import React from 'react'
import PropTypes from 'prop-types'

class Main extends React.Component {

  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <div className={this.props.submitted ? 'hidden' : ''}>
              <h2 className="major">Let's chat</h2>
              <p>Drop us a quick message using the form below and we will be in touch. If you prefer to talk to someone, give us a call on <a href="tel:0121 517 0083">0121 517 0083</a>.</p>
              <form method="post" action="#" onSubmit={this.props.handleSubmit}>
                <input type="hidden" name="token" value={this.props.token}  />
                <div className="field half first">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" id="name" required />
                </div>
                <div className="field half">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" id="email" required />
                </div>
                <div className="field">
                  <label htmlFor="message">Message</label>
                  <textarea name="message" id="message" rows="4" minLength="20" required></textarea>
                </div>
                <ul className="actions">
                  <li><input type="submit" value="Send Message" className="special" /></li>
                  <li><input type="reset" value="Reset" /></li>
                </ul>
              </form>
          </div>
          <div className={this.props.submitted ? '' : 'hidden'}>
              <h2 className="major">Thanks!</h2>
              <p>We will be in touch shortly.</p>
          </div>
            {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  submitted: PropTypes.bool,
}

export default Main

import React from 'react';
import PropTypes from 'prop-types';
import brewLogo from '../images/brew-logo-white.png';

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <img src={brewLogo} alt={"Brew"} width={320}/>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Brew - Burton</h1>
                <p>We're a full-service digital marketing agency, <br />dedicated to making Burton its digital best and proud sponsors of <br />the <a href="http://www.pitchero.com/clubs/burton/teams/57787" target="_blank">U13's Burton Rugby Football Club</a>.</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Let's chat</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
